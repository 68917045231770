import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TEXT from '../text';

class ProjectMatchingMotivationTable extends Component {
  render() {
    return (
      <div className="row col-12">
        <Paper style={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">{TEXT.candidateArchive.motivation.questions.title}</TableCell>
                <TableCell component="th">{TEXT.candidateArchive.motivation.answers.title}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(this.props.motivationData).map(key => (
                <TableRow key={key}>
                  <TableCell>
                    <Typography>{TEXT.candidateArchive.motivation.questions[key]}</Typography>
                  </TableCell>
                  <TableCell>
                    {Array.isArray(this.props.motivationData[key])
                      ? this.props.motivationData[key].map(item => (
                          <Typography key={`${key}-${item}`}>{TEXT.candidateArchive.motivation.answers[key][item]}</Typography>
                        ))
                      : this.props.motivationData[key]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

ProjectMatchingMotivationTable.defaultProps = {
  motivationData: {}
};

ProjectMatchingMotivationTable.propTypes = {
  motivationData: PropTypes.object
};

export default ProjectMatchingMotivationTable;
